<script>
  import Image from "$lib/components/Image.svelte";

  /** @type { string } */
  let className = "";
  export { className as class };

  export let data;
  let { altText, caption, aspectRatio, fullBleed, imageAsset } = data.fields;
</script>

<Image
  id={data.sys?.id}
  class={className}
  {altText}
  {caption}
  {imageAsset}
  {aspectRatio}
  isFullBleed={!!fullBleed}
/>
